module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","excludeOptions":{"separator":"."},"crumbLabelUpdates":[{"pathname":"/mogelijkheden","crumbLabel":"Mogelijkheden"},{"pathname":"/nieuws","crumbLabel":"Nieuws"},{"pathname":"/woo","crumbLabel":"Wet openbare overheid"},{"pathname":"/verhalen","crumbLabel":"Verhalen"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://beheer.wijzijnstark.nl/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
